exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archives-js": () => import("./../../../src/pages/archives.js" /* webpackChunkName: "component---src-pages-archives-js" */),
  "component---src-pages-deploystatus-js": () => import("./../../../src/pages/@deploystatus.js" /* webpackChunkName: "component---src-pages-deploystatus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-archive-temp-js": () => import("./../../../src/templates/archive-temp.js" /* webpackChunkName: "component---src-templates-archive-temp-js" */),
  "component---src-templates-project-temp-js": () => import("./../../../src/templates/project-temp.js" /* webpackChunkName: "component---src-templates-project-temp-js" */)
}

