import React from 'react';

export const BrownfoxLogo = props => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 428 170.4'
      style={props.style}
      className={props.className}
    >
      <g transform='translate(0 -0.002)'>
        <path
          className='fill_inherit'
          d='M0,148.9c2.5,13.5,13.6,21.4,30,21.4c15,0,26.8-8,26.8-22.1c0-10.6-6.5-17-18.2-19.8l-13.4-3.1
        c-3.6-0.8-5.7-2.5-5.7-5.5c0-3.8,3.5-6,8.6-6c5.9,0,9,2.9,10.5,6.7h17.5c-1.9-11.8-11.4-21-28.1-21c-15.2,0-26.3,8.8-26.3,22.2
        c0,11.6,7.6,16.9,17.6,19.1l13.2,2.8c4.4,1,6.5,2.5,6.5,5.7c0,4.2-3.8,6.6-9.3,6.6c-5.6,0-9.6-2.3-11-7L0,148.9z M67,151.9
        c0,12.7,6.7,18.3,18.5,18.3c3.3,0.1,6.6-0.5,9.7-1.6v-14.8c-1.8,0.7-3.7,1.1-5.6,1.1c-3.5,0-5.4-1.7-5.4-5.6v-16.7h10.5V119H84.3
        v-13.8H67V119h-7.4v13.6H67V151.9z M150.7,119h-17.5v30.5c-0.8,3.3-3.8,5.6-7.1,5.6c-4.5,0-6.5-3-6.5-7.3V119h-17.4v32.9
        c0,11.2,6.6,18.4,17.6,18.4c5.7,0,10.7-2.3,13.4-5.5v4.4h17.5V119z M190.6,169.3h17.5V96.7h-17.5V123c-2.3-2.7-7.3-5.1-12.4-5.1
        c-14.5,0-22.4,11.7-22.4,26.2s7.9,26.2,22.4,26.2c5.1,0,10-2.5,12.4-5.1L190.6,169.3z M190.6,150.7c-1.4,2.9-4.4,4.7-7.7,4.7
        c-6,0-9.6-4.8-9.6-11.3s3.6-11.4,9.6-11.4c3.2,0,6.2,1.8,7.7,4.7V150.7z M234.4,119h-17.5v50.2h17.5L234.4,119z M235.7,105.4
        c0-5.8-4.6-9.5-9.9-9.5s-9.9,3.7-9.9,9.5c0,5.7,4.6,9.4,9.9,9.4S235.7,111.1,235.7,105.4 M292,144.1c0-15.5-10.4-26.2-25.7-26.2
        c-15.2,0-25.7,10.7-25.7,26.2s10.5,26.2,25.7,26.2C281.6,170.4,292,159.7,292,144.1 M274.8,144.1c0,6.9-3,11.7-8.5,11.7
        c-5.4,0-8.4-4.8-8.4-11.7c0-6.9,3-11.8,8.4-11.8C271.8,132.3,274.8,137.2,274.8,144.1 M58,54.4c0,12.4-8.5,19.2-22.8,19.2H3.8V5
        h29.4C47.5,5,56,11.4,56,23.7c0,7.4-4.1,12.7-9.2,14.5C53.7,40.6,58.2,47.1,58,54.4 M31.1,32.2c5.2,0,8-2,8-6.3s-2.8-6.1-8-6.1
        H20.9v12.4H31.1z M20.9,58.8h12.1c5,0,7.8-2.7,7.8-6.6c0-4.1-2.8-6.7-7.8-6.7H20.9V58.8z M98.7,40c-2.3-1.1-4.7-1.7-7.3-1.6
        c-4.8,0-8,2.1-8.9,5.7v29.5H65V23.3h17.5v4.5c2.7-3.6,6.9-5.7,11.4-5.6c1.6-0.1,3.3,0.2,4.8,0.9L98.7,40z M127.5,74.7
        c-15.2,0-25.7-10.7-25.7-26.2s10.5-26.2,25.7-26.2c15.3,0,25.7,10.7,25.7,26.2S142.8,74.7,127.5,74.7 M127.5,36.6
        c-5.4,0-8.4,4.9-8.4,11.8s3,11.7,8.4,11.7c5.5,0,8.5-4.8,8.5-11.7S133,36.6,127.5,36.6 M203.7,50.6l8-27.2h16.7l-16.2,50.2h-14.8
        l-7.1-25.5l-7.1,25.5h-14.8l-16.2-50.2h17.4l7.9,27.2l7.6-27.2h11.2L203.7,50.6z M233.4,23.3h17.5v4.4c2.8-3.1,7.7-5.5,13.5-5.5
        c11.2,0,17.8,7.3,17.8,18.4v32.9h-17.4V44.9c0-4.1-2.1-7.1-6.6-7.1c-3.4-0.1-6.4,2.2-7.3,5.5v30.4h-17.5V23.3z M322.7,15.6
        c-1.3-0.7-2.7-1-4.1-1c-3.9,0-6.3,2-6.3,6v2.7h9.7v13.6h-9.7v36.6H295V36.9h-7.5V23.3h7.5v-3.7C295,7.6,302.3,0,314.6,0
        c2.8,0,5.5,0.4,8.1,1.3L322.7,15.6z M350.5,74.7c-15.2,0-25.7-10.7-25.7-26.2s10.5-26.2,25.7-26.2c15.3,0,25.7,10.7,25.7,26.2
        S365.8,74.7,350.5,74.7 M350.5,36.6c-5.4,0-8.4,4.9-8.4,11.8s3,11.7,8.4,11.7c5.5,0,8.5-4.8,8.5-11.7S356,36.6,350.5,36.6
         M410.4,47.9L428,73.6h-19l-8.6-13.1l-8.6,13.1h-18.1L391.3,48l-16.7-24.7h18.9l7.8,12.1l7.9-12.1h18.1L410.4,47.9z'
        />
      </g>
    </svg>
  );
};

export const Mail = props => (
  <svg
    width='29.817'
    height='21.471'
    viewBox='0 0 29.817 21.471'
    style={props.style}
    className={props.className}
  >
    <g transform='translate(-1412 -1008)'>
      <g transform='translate(1412 1008)'>
        <rect
          className='stroke_inherit'
          width='29.817'
          height='21.471'
          stroke='#000'
          strokeWidth='3'
        />
        <rect
          className='stroke_inherit'
          x='1.5'
          y='1.5'
          width='26.817'
          height='18.471'
          fill='none'
          stroke='#000'
          strokeWidth='3'
        />
      </g>
      <path
        className='stroke_inherit'
        d='M1412,1008l13.663,10.032L1439.312,1008'
        transform='translate(1.273 1.5)'
        fill='none'
        stroke='#000'
        strokeWidth='3'
      />
    </g>
  </svg>
);
export const ArrowAbout = props => {
  return (
    <svg
      width='21.461'
      height='21.461'
      viewBox='0 0 21.461 21.461'
      style={props.style}
      className={props.className}
    >
      <path
        className='stroke_inherit'
        d='M2398.35,3434.254h19.961v-19.961'
        transform='translate(-2398.35 -3414.292)'
        fill='none'
        stroke='#000'
        strokeWidth='3'
      />
      <path
        className='stroke_inherit'
        d='M2398.492,3433.672l-18.863-18.863'
        transform='translate(-2378.531 -3413.711)'
        stroke='#000'
        strokeWidth='3'
      />
    </svg>
  );
};
export const DirectionArrow = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='31.902'
    height='31.281'
    viewBox='0 0 31.902 31.281'
    style={props.style}
    className={props.className}
  >
    <g transform='translate(16.262 29.781) rotate(-135)'>
      <path
        className='stroke_inherit'
        stroke='#000'
        strokeWidth='3'
        d='M0,0H13.514V13.515'
        transform='translate(6.484 0)'
      />
      <path
        className='stroke_inherit'
        stroke='#000'
        strokeWidth='3'
        d='M20,0,0,20'
        transform='translate(0 0)'
      />
    </g>
  </svg>
);

export const Cross = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18.874'
    height='18.874'
    viewBox='0 0 18.874 18.874'
    style={props.style}
    className={props.className}
  >
    <g transform='translate(-1782.439 -565.439)'>
      <line
        x2='16.752'
        y2='16.752'
        strokeWidth='3'
        stroke='#000'
        className='stroke_inherit'
        transform='translate(1783.5 566.5)'
      />
      <line
        strokeWidth='3'
        x1='16.752'
        y2='16.752'
        className='stroke_inherit'
        stroke='#000'
        transform='translate(1783.5 566.5)'
      />
    </g>
  </svg>
);

export const BackButton = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='28'
    height='28.44'
    viewBox='0 0 28 28.44'
    style={props.style}
    className={props.className}
  >
    <g transform='translate(-321 -24.999)'>
      <g transform='translate(328.621 41.378)'>
        <path
          d='M0,0-5.5,5.5,0,11'
          fill='none'
          stroke='#0045ff'
          className='stroke_inherit'
          strokeWidth='3'
        />
      </g>
      <g transform='translate(324.936 46.878)'>
        <path
          d='M0,0H13.564s9,0,9-9v-2.379s0-9-9-9h-15.5'
          fill='none'
          className='stroke_inherit'
          stroke='#0045ff'
          strokeWidth='3'
        />
      </g>
    </g>
  </svg>
);
