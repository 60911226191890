const React = require("react") 
const {GlobalProvider} = require('./src/context/GlobalContext');

exports.wrapRootElement = ({ element }) => (
  <GlobalProvider>{element}</GlobalProvider>
)

exports.onRouteUpdate = ({ location }) => {
  // console.log('Route Updated', location.pathname);
};
exports.onClientEntry = async() => {
    //HOVER DETECTION
    if (!('ontouchstart' in document.documentElement)) {
      document.body.classList.add('onhover');
  }

  if (typeof IntersectionObserver === `undefined`) {
      await import(`intersection-observer`)
  }
};
exports.onPreRouteUpdate = ({ location }) => {
//   console.log('Preroute Update', location.pathname);
//   document.body.classList.add('preloading');
};