import React, { useEffect } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import { BrownfoxLogo } from 'components/svg';
import { getVar } from 'utils/common';

const Loading = () => {
  const resizeEvent = () => {
    runningRender();
  };
  useEffect(() => {
    //DOT INTERVAL FUNCTIONS
    // let currentText = '...';
    // const dotLoop = document.querySelector('.dotloop');
    // const dotInterval = setInterval(() => {
    //   if (currentText.length < 3) {
    //     currentText = currentText + '.';
    //   } else {
    //     currentText = '';
    //   }
    //   dotLoop.textContent = currentText;
    // }, 300);

    //RUNNING TEXT FUNCTION
    runningRender();
    if (typeof window !== `undefined`) {
      window.addEventListener('resize', resizeEvent, false);
    }
    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener('resize', resizeEvent, false);
      }
    };
  }, []);

  const runningRender = () => {
    if (typeof window !== 'undefined') {
      const windowW = getVar.windowW();
      const runningtextParent = document.querySelector('.runningtext > div');
      if (runningtextParent) {
        const runningtextLoad = runningtextParent.querySelector('span');
        const runningtextNode = document.createElement('span');
        runningtextNode.textContent = 'Loading';
        // GET HOW MUCH TO RENDER
        const numberofload =
          Math.round(windowW / runningtextLoad.offsetWidth) * 2.5;
        // CLEAR CHILD
        const allspan = runningtextParent.querySelectorAll('span');
        if (allspan.length < numberofload) {
          let child = runningtextParent.lastElementChild;
          while (child) {
            runningtextParent.removeChild(child);
            child = runningtextParent.lastElementChild;
          }
          for (var i = 0; i < numberofload; i++) {
            runningtextParent.appendChild(runningtextNode.cloneNode(true));
          }
        }
      }
    }
  };

  return (
    <GlobalContext.Consumer>
      {context => (
        <div>
          <div
            className='loading__container'
            style={{ background: context.main_color }}
          >
            <BrownfoxLogo
              className='loading_logo'
              style={{ fill: context.main_color, stroke: context.main_color }}
            />
            <div className='runningtext' style={{ color: context.main_color }}>
              <div>
                <span>Loading</span>
              </div>
            </div>
            <div className='dotanimation' style={{ color: context.main_color }}>
              Loading
              <span className='dotloop'>...</span>
            </div>
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
};

export default Loading;
