import React from 'react';
import { MediaCheck } from 'utils/mediacheck';
import ReactDOM from 'react-dom';
export let globalVar = {
  main_color: '#00ffff',
};

export class Portal extends React.Component {
  constructor() {
    super();
    // 1: Create a new div that wraps the component
    if (typeof document !== `undefined`) {
      this.el = document.createElement('div');
      this.renderelementname = `render${Math.round(Math.random() * 1000)}`;
      this.el.classList.add(this.renderelementname);
    }
  }
  // 2: Append the element to the DOM when it mounts
  componentDidMount = () => {
    const portalRoot =
      typeof document !== `undefined`
        ? document.querySelector('#Portal')
        : null;
    if (portalRoot && portalRoot !== null && this.el)
      portalRoot.appendChild(this.el);
  };
  // 3: Remove the element when it unmounts
  componentWillUnmount = () => {
    if (typeof document !== `undefined`) {
      const portalRoot =
        typeof document !== `undefined`
          ? document.querySelector('#Portal')
          : null;

      if (portalRoot && portalRoot !== null && this.el)
        portalRoot.removeChild(this.el);
    }
  };
  render() {
    const { children } = this.props;
    if (typeof document !== `undefined`) {
      return ReactDOM.createPortal(children, this.el);
    } else {
      return null;
    }
  }
}
export const checkFile = file_url => {
  if(typeof file_url === 'string'){
    if(!file_url.startsWith('http') && !file_url.startsWith('localhost')){
      if(!file_url.startsWith('/')){
        file_url = `/${file_url}`;
      }
    }
  }
  return file_url;
}
export const LinkHoverColor = e => {
  if (!MediaCheck.touch()) {
    switch (e.type) {
      case 'mouseenter':
        e.currentTarget.style.color = globalVar.main_color;
        e.currentTarget.style.stroke = globalVar.main_color;
        e.currentTarget.style.fill = globalVar.main_color;
        break;
      case 'mouseleave':
        e.currentTarget.style.color = null;
        e.currentTarget.style.stroke = null;
        e.currentTarget.style.fill = null;
        break;
      default:
        return;
    }
  }
};

export const getVar = {
  scrollP: () => {
    return window.pageYOffset || document.documentElement.scrollTop;
  },
  windowH: () => {
    return (
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName('body')[0].clientHeight
    );
  },
  windowW: () => {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.getElementsByTagName('body')[0].clientWidth
    );
  },
  documentH: () => {
    return document.body.scrollHeight || document.body.offsetHeight;
  },
  padding: {
    desktop: 50,
  },
};
