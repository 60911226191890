import React, { useEffect, useRef, useContext } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Link from 'gatsby-plugin-transition-link';
import { GlobalContext } from '../context/GlobalContext';
import { BrownfoxLogo } from 'components/svg';
import anime from 'animejs';
import { getVar } from 'utils/common';
import { Mail } from 'components/svg';
import { MediaCheck } from 'utils/mediacheck';

import { ResponsiveVH } from 'utils/responsive-vh';
const Navigation = (props) => {
  const context = useContext(GlobalContext);
  let enableAnimation = useRef(true);
  const scrollEvent = (e) => {
    const getDocumentH =
      typeof e !== 'undefined' && e.type === 'OverlayScroll' && e.detail.target
        ? e.detail.target.scrollHeight
        : getVar.documentH();

    // GET HEIGHT DIFFERENT BETWEEN CONTENT AND WINDOW;
    const diffH = getDocumentH - getVar.windowH();

    const mainLogo = document.querySelector('#mainlogo');
    mainLogo.style.height = '';
    let treshold = 60 + getVar.padding.desktop;
    if (diffH < treshold) {
      enableAnimation.current = false;
    } else {
      enableAnimation.current = true;
    }
    if (enableAnimation.current && context.enableScrollToggle) {
      const initHeight = mainLogo.offsetHeight;

      const getScrollP =
        typeof e !== 'undefined' &&
        e.type === 'OverlayScroll' &&
        e.detail.scrollposition
          ? e.detail.scrollposition
          : getVar.scrollP();

      let scrollChange = getScrollP - getVar.padding.desktop;
      scrollChange = scrollChange > 0 ? scrollChange : 0;
      let newHeight =
        initHeight - (scrollChange = scrollChange > 0 ? scrollChange : 0) / 3;
      mainLogo.style.height = `${newHeight > 0 ? newHeight : 1}px`;
    } else {
      mainLogo.style.height = null;
    }
  };
  context.closeOverlayDom = (d) => {
    // SELECTOR
    const viewinfoArDom = document.querySelector('.viewinfo-archive');
    const viewinfoPrDom = document.querySelector('.viewinfo-project');
    const mobileBackAll = document.querySelectorAll('.mobilebackbutton');
    const archiveInformation = document.querySelector('.archive_information');
    const projectInformation = document.querySelector('.project_information');

    const duration = d ? d : 750;
    if (viewinfoArDom) {
      anime({
        targets: viewinfoArDom,
        opacity: [1, 0],
        easing: 'easeInOutCubic',
        duration: duration,
      });
    }
    if (viewinfoPrDom) {
      anime({
        targets: viewinfoPrDom,
        opacity: [1, 0],
        easing: 'easeInOutCubic',
        duration: duration,
      });
    }
    if (archiveInformation) {
      anime({
        targets: archiveInformation,
        opacity: [1, 0],
        easing: 'easeInOutCubic',
        duration: duration,
      });
    }
    if (projectInformation) {
      anime({
        targets: projectInformation,
        opacity: [1, 0],
        easing: 'easeInOutCubic',
        duration: duration,
      });
    }
    if (mobileBackAll.length > 0) {
      mobileBackAll.forEach((mobileback) => {
        anime({
          targets: mobileback,
          opacity: [1, 0],
          easing: 'easeInOutCubic',
          duration: duration,
        });
      });
    }
  };
  useEffect(() => {
    const fitHeightMenu = new ResponsiveVH({ target: '#NavigationMenu' });
    const resizeEvent = (e) => {
      scrollEvent();
      if (!MediaCheck.width.mobile()) {
        context.toggleMobileMenu(false);
      }
    };
    if (typeof window !== `undefined`) {
      resizeEvent();
      window.addEventListener('scroll', scrollEvent, false);
      window.addEventListener('OverlayScroll', scrollEvent, true);
      window.addEventListener('resize', resizeEvent, false);
    }

    return () => {
      if (typeof window !== `undefined`) {
        fitHeightMenu.kill();
        window.removeEventListener('scroll', scrollEvent, false);
        window.removeEventListener('OverlayScroll', scrollEvent, false);
        window.removeEventListener('resize', resizeEvent, false);
      }
    };
  }, []);

  context.toggleMobileMenu = (value) => {
    const navMenuQuery = document.querySelector('#NavigationMenu');
    const mobileMenuQuery = document.querySelector('#MobileMenu');
    if (navMenuQuery) {
      if (typeof value === 'boolean') {
        if (value) {
          navMenuQuery.classList.add('menuopen');
          mobileMenuQuery.classList.add('menuopen');
        } else {
          navMenuQuery.classList.remove('menuopen');
          mobileMenuQuery.classList.remove('menuopen');
        }
      } else {
        if (navMenuQuery.classList.contains('menuopen')) {
          navMenuQuery.classList.remove('menuopen');
          mobileMenuQuery.classList.remove('menuopen');
          context.disableScrollBody.enable();
        } else {
          navMenuQuery.classList.add('menuopen');
          mobileMenuQuery.classList.add('menuopen');
          context.disableScrollBody.disable();
        }
      }
    }
  };

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const contact_email = data.markdownRemark.frontmatter.contact_email;
        const contact_sm = data.markdownRemark.frontmatter.contact_sm;
        const menu_label = data.markdownRemark.frontmatter.menu_label;
        const headerlinks = [
          { label: menu_label.projects, url: '/projects' },
          { label: menu_label.info, url: '/info' },
        ];
        return (
          <GlobalContext.Consumer>
            {(context) => (
              <>
                <Link
                  id='mainlogo'
                  activeClassName='onpage'
                  exit={{
                    length: 0.75,
                    trigger: ({ exit, node }) => {
                      if (typeof window !== 'undefined') {
                        document.body.classList.add('transitioning');
                        context.toggleMobileMenu(false);
                      }
                      anime({
                        targets: node,
                        opacity: [1, 0],
                        easing: 'easeInOutCubic',
                        duration: 750,
                      });

                      context.closeOverlayDom(500);

                      if (MediaCheck.touch()) {
                        const scrollTop =
                          (document.scrollingElement &&
                            document.scrollingElement.scrollTop) ||
                          document.body.scrollTop ||
                          window.pageYOffset;

                        node.style.overflowY = 'hidden';
                        node.style.height = `${window.innerHeight}px`;
                        node.scrollTop = scrollTop;
                      }
                    },
                  }}
                  entry={{
                    delay: 0.25,
                    trigger: ({ entry, node }) => {
                      anime({
                        targets: node,
                        opacity: [0, 1],
                        easing: 'easeInOutCubic',
                        duration: 750,
                      });
                    },
                  }}
                  to='/'
                >
                  <BrownfoxLogo
                    style={{
                      fill: context.main_color,
                      stroke: context.main_color,
                    }}
                  />
                </Link>
                <div id='NavigationMenu'>
                  <header style={{ color: context.main_color }}>
                    <div className='header_list'>
                      {headerlinks.map((eachlink, index) => (
                        <Link
                          key={index}
                          activeClassName='onpage'
                          partiallyActive={true}
                          exit={{
                            length: MediaCheck.width.mobile() ? 0.05 : 0.75,
                            trigger: ({ exit, node }) => {
                              //CLEAR ALL HEADER
                              //ADD BODY TRANSITIONING
                              if (typeof window !== 'undefined') {
                                context.toggleMobileMenu(false);
                                document.body.classList.add('transitioning');
                              }

                              if (MediaCheck.touch()) {
                                const scrollTop =
                                  (document.scrollingElement &&
                                    document.scrollingElement.scrollTop) ||
                                  document.body.scrollTop ||
                                  window.pageYOffset;

                                node.style.overflowY = 'hidden';
                                node.style.height = `${window.innerHeight}px`;
                                node.scrollTop = scrollTop;
                              }

                              if (MediaCheck.width.mobile()) {
                                anime({
                                  targets: node,
                                  opacity: [0],
                                  easing: 'easeInOutCubic',
                                  duration: 1,
                                });
                                node.style.display = 'none';
                                context.closeOverlayDom(1);
                              } else {
                                anime({
                                  targets: node,
                                  opacity: [1, 0],
                                  easing: 'easeInOutCubic',
                                  duration: 750,
                                });
                                context.closeOverlayDom(750);
                              }
                            },
                          }}
                          entry={{
                            delay: MediaCheck.width.mobile() ? 0.1 : 0.25,
                            trigger: ({ node }) => {
                              anime({
                                targets: node,
                                opacity: [0, 1],
                                easing: 'easeInOutCubic',
                                duration: 750,
                                complete: () => {},
                              });
                            },
                          }}
                          to={eachlink.url}
                        >
                          {eachlink.label}
                          <span>
                            <span style={{ background: context.main_color }} />
                          </span>
                        </Link>
                      ))}
                    </div>
                  </header>
                  <footer style={{ color: context.main_color }}>
                    <div>
                      {contact_sm.map((contact, index) => (
                        <a
                          href={contact.link}
                          target='_blank'
                          key={index}
                          rel='noopener noreferrer'
                        >
                          {contact.title}
                        </a>
                      ))}
                    </div>
                    <div className='mail'>
                      <a
                        href={`mailto:${contact_email}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <Mail
                          style={{
                            fill: context.main_color,
                            stroke: context.main_color,
                          }}
                        />
                        <span>{contact_email}</span>
                      </a>
                    </div>
                  </footer>
                </div>
                <div
                  id='MobileMenu'
                  role='button'
                  tabIndex='0'
                  onClick={() => {
                    context.toggleMobileMenu();
                  }}
                  onKeyDown={(e) => {
                    if (e.which === 32) context.toggleMobileMenu();
                  }}
                >
                  <span
                    style={{
                      background: context.main_color,
                    }}
                  ></span>
                  <span
                    style={{
                      background: context.main_color,
                    }}
                  ></span>
                </div>
                <div className='mobileMail'>
                  <a
                    href='https://google.com'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Mail
                      style={{
                        fill: context.main_color,
                        stroke: context.main_color,
                      }}
                    />
                    <span>info@brownfoxstudio.com</span>
                  </a>
                </div>
              </>
            )}
          </GlobalContext.Consumer>
        );
      }}
    />
  );
};

const query = graphql`
  query {
    markdownRemark(
      frontmatter: {
        issetting: { eq: true }
        contenttype: { eq: "general_setting" }
      }
    ) {
      frontmatter {
        menu_label {
          projects
          info
        }
        contact_sm {
          title
          link
        }
        contact_email
      }
    }
  }
`;

export default Navigation;