import React, { useState } from 'react';
// import { DisableScroll } from 'utils/disablescroll';

const defaultState = {
  main_color: '#0045FF',
  webinit: false,
  weburl: '',
  webname: '',
  disableScrollBody: null,
  osRef: null,
  enableScrollToggle: true,
  toggleMobileMenu: () => {},
  disableLayoutScroll: () => {}
};

export const GlobalContext = React.createContext(defaultState);

export const GlobalProvider = props => {
  const [state] = useState({ ...defaultState });

  return (
    <GlobalContext.Provider
      value={{
        main_color: state.main_color,
        webinit: state.webinit,
        weburl: state.weburl,
        webname: state.webname,
        osRef: state.osRef,
        enableScrollToggle: state.enableScrollToggle,
        disableScrollBody: state.disableScrollBody,
        toggleMobileMenu: state.toggleMobileMenu,
        disableLayoutScroll: state.disableLayoutScroll
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
