import React from 'react';
import Nav from 'components/navigation';
import Loading from 'components/loading';
// import { TransitionPortal } from 'gatsby-plugin-transition-link';
// import { Portal } from 'utils/common';

const MainLayout = (props) => {
  return (
    <>
      <Loading />
      <div id='Portal'></div>
      <Nav />
      {props.children}
    </>
  );
};

export default MainLayout;
